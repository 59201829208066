import React, { useEffect, useState } from "react";
import { Snackbar } from "@material-ui/core";
import './wallet-details.scss';
import '../../connectWallet.scss';
import Alert from "@material-ui/lab/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import { Footer } from "../../components/footer";

interface Column {
  id: 'transaction_id' | 'from_address' | 'to_address' | 'gas_subsidizer' | 'on_block' | 'amount' | 'created_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => any;
}

const truncate = (text: any, startChars: number, endChars: number, maxLength: number) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while ((start.length + end.length) < maxLength) {
      start = start + '.';
    }
    return start + end;
  }
  return text;
}

const columns: readonly Column[] = [
  { id: 'transaction_id', label: 'Transaction Id', minWidth: 170, format: (value) => truncate(value, 5, 5, 16) },
  { id: 'from_address', label: 'From Address', minWidth: 100 },
  {
    id: 'to_address',
    label: 'To Address',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5, 5, 16),
  },
  {
    id: 'gas_subsidizer',
    label: 'Gas Subsidizer',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5, 5, 16),
  },
  {
    id: 'on_block',
    label: 'On Block',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'created_at',
    label: 'Confirmed at',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  }
];

interface Data {
  transaction_id: string;
  from_address: string;
  to_address: string;
  gas_subsidizer: string;
  on_block: number;
  amount: string;
  created_at: string;
}

function createData(
  transaction_id: string,
  from_address: string,
  to_address: string,
  gas_subsidizer: string,
  on_block: number,
  amount: string,
  created_at: string

): Data {
  return { transaction_id, from_address, to_address, gas_subsidizer, on_block, amount, created_at };
}

const rows: any = [];

const WalletDetails = (props: any) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [walletTransections, setWalletTransections] = useState([]);
  const [walletSummary, setWalletSummary]: any = useState({});

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getSummary() {
    let urlParam = window.location.pathname.split('/')[2];
    let body = { "address": `${urlParam}` }
    axios.post("https://block.axionprotocol.com//get-wallet-balance/", body)
      .then(
        (result: any) => {
          setIsloading(false);
          setWalletSummary(result.data);
        },
        (error) => {
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
          setIsloading(false);
        }
      )
  }

  useEffect(() => {
    let urlParam = window.location.pathname.split('/')[2];
    setIsloading(true);
    (async function () {
      try {

        let date = new Date();
        axios.get(`https://block.axionprotocol.com//wallet-transactions/${urlParam}/?random=${(Math.random() + 1).toString(36).substring(7)}`)
          .then(
            (result: any) => {
              setWalletTransections(result.data);
              if (result.data.length > 0) {
                for (let transection of result.data) {
                  rows.push(
                    createData(truncate(transection.transaction_id, 5, 5, 16), truncate(transection.from_address, 5, 5, 16), truncate(transection.to_address, 5, 5, 16), truncate(transection.gas_subsidizer, 5, 5, 16), transection.on_block, parseFloat(transection.amount).toFixed(2), transection.created_at)
                  )
                }
              }

              setIsloading(false);
              getSummary();
            },
            (error) => {
              setAlertState({
                open: true,
                message: "Something went wrong!!",
                severity: "error",
              });
              setIsloading(false);
              getSummary();
            }
          )

      } catch (e) {
        setIsloading(false);
      }
    })();

  }, [])

  return (
    <main className="wallet-details-container">
      <div className="back-trans">
        <img className="wallet-logo" src="../assets/img/favicon.png"></img>
        {isLoading && walletSummary ? (
          <Box className="loader-box" sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="trans-container">
            <h1 style={{ textAlign: 'center' }}>Wallet Transactions</h1>
            {walletSummary &&
              <div className="wallet-details">
                {walletSummary.address &&
                  <div className="wallet-table">
                    <span className="title">Account Name : </span>
                    <span>{truncate(walletSummary.address, 5, 5, 16)}</span>
                  </div>
                }

                <div className="wallet-table">
                  <span className="title">Balance : </span>
                  <span>{walletSummary?.balance}</span>
                </div>
              </div>
            }

            {walletTransections.length > 0 ? (
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer className="table-container" sx={{ maxHeight: 400, minHeight: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={(Math.random() + 1).toString(36).substring(7)}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={(Math.random() + 1).toString(36).substring(7)}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={(Math.random() + 1).toString(36).substring(7)} align={column.align}>
                                    {column.id !== 'created_at' ? (
                                      value
                                    ) : (
                                      <Moment fromNow>{value}</Moment>
                                    )}

                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <div>
                <h3>No Transections available yet!!</h3>
              </div>
            )}

          </div>

        )}


        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
        <Footer />
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}


export default WalletDetails;

