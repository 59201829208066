import { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import './recent-activity.scss';
import Alert from "@material-ui/lab/Alert";
import Card from '@mui/material/Card';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import Moment from 'react-moment';

const RecentActivity = (props: any) => {

  const [recentActivity, setRecentActivity] = useState<any>([]);
  const [isLoading, setIsloading] = useState(true);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  useEffect(()=> {
    setIsloading(true);
    (async function () {
      try {
        let walletDetails = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
        let date = new Date();
        axios.get(`https://block.axionprotocol.com//wallet-transactions/${walletDetails.address}?random=${date}`)
        .then(
          (result:any) => {
            setIsloading(false);
            setRecentActivity(result.data)
          },
          (error) => {
            setAlertState({
              open: true,
              message: "Something went wrong!!",
              severity: "error",
            });
            setIsloading(false);
          }
        )
      } catch (e) {
        setIsloading(false);
      }
    })();
    
  }, [])

  const truncate = (text:any, startChars:number, endChars:number, maxLength:number) => {
    if (text.length > maxLength) {
        var start = text.substring(0, startChars);
        var end = text.substring(text.length - endChars, text.length);
        while ((start.length + end.length) < maxLength)
        {
            start = start + '.';
        }
        return start + end;
    }
    return text;
  }
 
  return (
    <div className="recent-activity-container">
      {isLoading ? (
        <Box sx={{ display: 'flex', alignItems:"center", justifyContent:"center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ): (
        <>
        {recentActivity.length > 0 ? (
          <div>
            <h1 style={{margin: "10px 0"}}>Recent Activity</h1>
            <div style={{maxHeight: "365px", overflowY: "auto"}}>
              {recentActivity.map((activity:any) => {
                return (
                  <Card className="card-style" key={activity.to_address} variant="outlined">
                    <CardContent className="card-content">
                      <CheckCircleOutlineIcon className="check-icon"></CheckCircleOutlineIcon>
                      <div className="card-details">
                        <div style={{flexBasis:"75%"}}>
                          <div style={{fontWeight: "600", color:"#fff"}}>{truncate(activity.transaction_id, 5,5,16)}</div>
                          <div style={{marginTop: "5px", color:"gray"}}>Transaction confirmed!</div>
                          <div className="extra-details">
                            <div>
                              <span>Amount: </span><span>{activity.amount}</span>
                            </div>
                            <div>
                              <span>From: </span><span>{truncate(activity.from_address, 5,5,16)}</span>
                            </div>
                            <div>
                              <span>To: </span><span>{truncate(activity.to_address, 5,5,16)}</span>
                            </div>
                            <div>
                              <span>Gas Subsidizer: </span><span>{truncate(activity.gas_subsidizer, 5,5,16)}</span>
                            </div>
                            <div>
                              <span>On Block: </span><span>{activity.on_block}</span>
                            </div>
                          </div>
                        </div>
                        <div style={{flexBasis:"25%", justifyContent:"flex-end", color:"gray"}}>
                          <Moment fromNow>{activity.created_at}</Moment>
                        </div>
                      </div>
                      
                    </CardContent>
                  </Card>
                )
                
                })
              }
              
            </div>
          </div>
        ) : (
          <div className="no-activety">
            <h1>No Recent Activity!!</h1>
          </div>
        )}
        </>
      )}

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
      
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}


export default RecentActivity;

