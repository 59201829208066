import { useEffect, useLayoutEffect, useState } from "react";
import './all-transections.scss';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';

interface Column {
  id: 'transaction_id' | 'from_address' | 'to_address' | 'gas_subsidizer' | 'on_block' | 'amount' | 'created_at';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: any) => any;
}

const truncate = (text:any, startChars:number, endChars:number, maxLength:number) => {
  if (text.length > maxLength) {
      var start = text.substring(0, startChars);
      var end = text.substring(text.length - endChars, text.length);
      while ((start.length + end.length) < maxLength)
      {
          start = start + '.';
      }
      return start + end;
  }
  return text;
}

const columns: readonly Column[] = [
  { id: 'transaction_id', label: 'Transaction Id', minWidth: 170, format: (value) => truncate(value, 5,5,16) },
  { id: 'from_address', label: 'From Address', minWidth: 100 },
  {
    id: 'to_address',
    label: 'To Address',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5,5,16),
  },
  {
    id: 'gas_subsidizer',
    label: 'Gas Subsidizer',
    minWidth: 170,
    align: 'right',
    format: (value) => truncate(value, 5,5,16),
  },
  {
    id: 'on_block',
    label: 'On Block',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'created_at',
    label: 'Confirmed at',
    minWidth: 170,
    align: 'right',
    format: (value) => value
  }
];

interface Data {
  transaction_id: string;
  from_address: string;
  to_address: string;
  gas_subsidizer: string;
  on_block: number;
  amount: string;
  created_at: string;
}

function createData(
  transaction_id: string,
  from_address: string,
  to_address: string,
  gas_subsidizer: string,
  on_block: number,
  amount: string,
  created_at: string

): Data {
  return { transaction_id, from_address, to_address, gas_subsidizer, on_block, amount, created_at };
}


const AllTransections = (props:any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<any>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
useEffect(() => {
  let arr:any = [];
  for(let transection of props.transections) {
    arr.push(
      createData(truncate(transection.transaction_id, 5,5,16), transection.from_address, transection.to_address, transection.gas_subsidizer, transection.on_block , parseFloat(transection.amount).toFixed(2), transection.created_at)
    )
  }
  setRows(arr)
}, [props.transections])

 

  const onTableClick = (address:any) => {
    window.location.href= `/account/${address}`;
  }


  return (
    <div className="transections-container">
      <h1 style={{marginTop:"20px"}}>Recent Transactions</h1>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={(Math.random() + 1).toString(36).substring(7)}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={(Math.random() + 1).toString(36).substring(7)}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell  key={(Math.random() + 1).toString(36).substring(7)} align={column.align}>
                          {/* { column.id !=='created_at' ? (
                            value
                          ) : (
                            <Moment fromNow>{value}</Moment>
                          ) } */}

                          { column.id !=='created_at' ? (
                            <>
                              { column.id === 'from_address' || column.id === 'to_address' || column.id === 'gas_subsidizer' ? (
                                <span style={{cursor: "pointer"}} onClick={() => onTableClick(value)}>{truncate(value,  5,5,16)}</span>
                              ) : (
                                value
                              ) }
                            </>
                          ) : (
                            <Moment fromNow>{value}</Moment>
                          ) }   
                          
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
   
  );
};

export default AllTransections;


