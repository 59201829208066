import { useEffect, useState } from "react";
import './finished.scss';
import Button from '@material-ui/core/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import HelpIcon from '@mui/icons-material/Help';

const Finished = (props:any) => {
  
  const onFinished = () => {
    props.onFinished();
  }
  
  return (
    <div className="finished-container">
      <h1>You're all done!</h1>
      <p>
        Follow along with product updates or reach out if you have any questions.
      </p>
      
      <div className="btn_wrapper">
        <a target="_blank" rel="noreferrer" href="https://twitter.com/AxionProtocol">  
            <Button
                variant="contained"
                color="default"
                className="social_btn"
                startIcon={<TwitterIcon />}
            >
                Follow us on Twitter
            </Button>
        </a>
        <a target="_blank" rel="noreferrer" href="https://docs.axionprotocol.com/">
            <Button
                variant="contained"
                color="default"
                className="social_btn"
                startIcon={<HelpIcon />}
            >
                Visit the help center
            </Button>
        </a>
      </div>

      <Button className="finsh_btn" onClick={onFinished} variant="contained">
          Finish
        </Button> 

    </div>
  );
};

export default Finished;

