export const Footer = ()=>{
    return(
        <footer className='footer-secion'>
          <div className="footer__copy">© Axion Protocol. All rigths reserved 2024</div>
          <div className="footer__social">
            <a href="https://twitter.com/AxionProtocol" target="_blank" rel="noreferrer" className="footer__social-link">
              <i className='bx bxl-twitter' ></i>
            </a>
            <a href="https://t.me/AxionProtocol" target="_blank" rel="noreferrer" className="footer__social-link">
              <i className="bx bxl-telegram" aria-hidden="true"></i>
            </a>
           
            <a href="https://s.axionprotocol.com/discord/" target="_blank" rel="noreferrer" className="footer__social-link">
              <i className='bx bxl-discord' ></i>
            </a>
          </div>
        </footer>
    )
}