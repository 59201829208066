
import ConnectWallet from "./connectWallet";

const App = () => {
  return (
    <ConnectWallet />
  );
};

export default App;
