import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Snackbar } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import './connectWallet.scss';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
// import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Alert from "@material-ui/lab/Alert";
import Password from "./containers/password/password";
import Finished from "./containers/finished/finished";
import RecoverySecretPharse from "./containers/recovery_secret_pharse/recovery_secret_pharse";
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import LogoutIcon from '@mui/icons-material/Logout';
import Summary from "./containers/summary/summary";
import RecentActivity from "./containers/recent-activity/recent-activity";
import WidgetsIcon from '@mui/icons-material/Widgets';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SecretPharse from "./containers/secret_pharse/secret_pharse";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import AllTransections from "./containers/all-transections/all-transections";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ape1 from './images/ape1.avif';
import ape2 from './images/ape2.avif';
import ape3 from './images/ape3.avif';
import ape4 from './images/ape4.avif';
import { Footer } from "./components/footer";

// const QontoConnector = withStyles({
//   alternativeLabel: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   active: {
//     '& $line': {
//       borderColor: '#f02050',
//     },
//   },
//   completed: {
//     '& $line': {
//       borderColor: '#f02050',
//     },
//   },
//   line: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// })(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#f02050',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#f02050',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const stepperUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const truncate = (text: any, startChars: number, endChars: number, maxLength: number) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while ((start.length + end.length) < maxLength) {
      start = start + '.';
    }
    return start + end;
  }
  return text;
}

const walletCode = (string: any) => {
  return truncate(string, 5, 5, 16)
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConnectWallet = () => {
  const [isCreateWallet, setIsCreateWallet] = useState(false);
  const [isSecretRecovery, setIsSecretRecovery] = useState(false);
  const [isStepperNextStep, setIsStepperNextStep] = useState(false);
  const stepperClasses = stepperUseStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [hasConnectWalletDone, setConnectWalletDone] = useState(false);
  const [hasScreenStatus, setHasScreenStatus] = useState(false);
  const [hasSignedIn, setHasSignedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedTab, setSelectedTab] = useState(1);
  const [isForgotPassword, setForgotPasswordFlag] = useState(false);
  const [forgotPassword, setForgotPassword] = useState('');
  const [allTransections, setAllTransections] = useState([]);
  const [address, setAddress] = useState('');
  const steps = getSteps();
  const [isLoading, setIsloading] = useState(true);
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getSteps() {
    if (!isSecretRecovery)
      return ['Secret Recovery Phrase', 'Create a password', 'finished'];
    else
      return ['First Secret Recovery Phrase', 'Create a password', 'finished'];
  }

  const onCopy = (text: any) => {
    navigator.clipboard.writeText(text);
    setAlertState({
      open: true,
      message: "Text copied successfully!!",
      severity: "info",
    });
  }

  const continueClick = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const OnRecoveryPhase = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const OnAccountImport = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function getStepContent(step: number) {
    if (!isSecretRecovery) {
      switch (step) {
        case 0:
          return <SecretPharse onSaveItClick={(secretPharse: any) => onSaveItClick(secretPharse)}></SecretPharse>;
        case 1:
          return <Password onContinue={() => continueClick()} />;
        case 2:
          return <Finished onFinished={() => onFinished()} />;
        default:
          return 'Unknown step';
      }
    } else {
      switch (step) {
        case 0:
          return <RecoverySecretPharse OnRecoveryPhase={() => OnRecoveryPhase()} />;
        // case 1:
        //   return <Account OnAccountImport={() => OnAccountImport()} />;
        case 1:
          return <Password onContinue={() => continueClick()} />;
        case 2:
          return <Finished onFinished={() => onFinished()} />;
        default:
          return 'Unknown step';
      }
    }

  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCardBack = () => {
    setIsCreateWallet(false);
  }

  const onSaveItClick = (secretPharse: any) => {
    if (countWords(secretPharse) === 12 || countWords(secretPharse) === 24) {
      setIsStepperNextStep(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      localStorage.setItem('secretPharse', secretPharse);
    } else {
      setAlertState({
        open: true,
        message: "Invalid secret recovery phrase.",
        severity: "error",
      });
    }
  }

  function countWords(s: any) {
    s = s.replace(/(^\s*)|(\s*$)/gi, "");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, " ");//2 or more space to 1
    s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
    return s.split(' ').filter(function (str: any) { return str !== ""; }).length;
    //return s.split(' ').filter(String).length; - this can also be used
  }

  const onSecretClick = () => {
    setIsCreateWallet(true);
    setIsSecretRecovery(true);
    setActiveStep(0);
    setIsStepperNextStep(true);
  }

  const onCreateWallet = () => {
    setIsCreateWallet(true);
    setIsSecretRecovery(false);
    setActiveStep(0);
  }

  const onFinished = () => {
    setIsloading(true);
    let secretPharse = localStorage.getItem('secretPharse');
    let body = { "seed": secretPharse }
    axios.post("https://block.axionprotocol.com//keys-from-seed/", body)
      .then(
        (result: any) => {
          setIsloading(false);
          localStorage.setItem('keys-from-seed', JSON.stringify(result.data));
          setConnectWalletDone(true);
          localStorage.setItem('hasConnectWalletDone', 'yes');
          sessionStorage.setItem('hasSignedIn', 'yes');
          setHasSignedIn(true);
          setAddress(result.data.address);
        },
        (error) => {
          setIsloading(false);
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
        }
      )
  }

  const passwordChange = (event: any) => {
    setPassword(event.target.value);
  }

  const onUnlock = () => {
    let storePassword = localStorage.getItem('setPassword');
    if (storePassword !== password) {
      setAlertState({
        open: true,
        message: "You have enter wrong password.",
        severity: "error",
      });
    } else {
      sessionStorage.setItem('hasSignedIn', 'yes');
      setHasSignedIn(true);
    }
  }

  const getAllTransections = () => {
    let date = new Date();
    axios.get(`https://block.axionprotocol.com//recent-transactions/?random=${date}`)
      .then(
        (result) => {
          setAllTransections(result.data);
        },
        (error) => {

        }
      )
  }

  useEffect(() => {
    setIsloading(true);
    (async function () {
      try {
        let getWalletStatus = await localStorage.getItem('hasConnectWalletDone');
        if (getWalletStatus === 'yes') {
          setConnectWalletDone(true);
          let walletDetails: any = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
          setAddress(walletDetails.address);
        }
        setHasScreenStatus(true);

        let hasSignedInStatus = await sessionStorage.getItem('hasSignedIn');
        if (hasSignedInStatus === 'yes')
          setHasSignedIn(true);

        getAllTransections();
        setIsloading(false);
      } catch (e) {
        console.error(e);
        setIsloading(false);
      }
    })();

  }, [])

  const getContent = () => {
    if (selectedTab === 1) {
      return <Summary></Summary>
    } else if (selectedTab === 2) {
      return (<div>
        <Card style={{ height: "454px", overflowY: "scroll", background: "rgba(0,0,0,0.1)" }}>
          <div className="gallery">
            <div className="gallery-item">
              <a href="">
                <img className="gallery-image" src={ape1} alt="ape-nft" />
              </a>
            </div>
            <div className="gallery-item">
              <a href="">
                <img className="gallery-image" src={ape2} alt="ape-nft" />
              </a>
            </div>
            <div className="gallery-item">
              <a href="">
                <img className="gallery-image" src={ape3} alt="ape-nft" />
              </a>
            </div>
            <div className="gallery-item">
              <a href="">
                <img className="gallery-image" src={ape4} alt="ape-nft" />
              </a>
            </div>
          </div>
          
        </Card>
      </div>)
    } else if (selectedTab === 3) {
      return <RecentActivity />
    }
  }

  const exitFromWallet = () => {
    localStorage.removeItem('secretPharse');
    setConnectWalletDone(false);
    localStorage.removeItem('hasConnectWalletDone');
    sessionStorage.removeItem('hasSignedIn');
    setHasSignedIn(false);
    localStorage.removeItem('setPassword');
    localStorage.removeItem('keys-from-seed');
    window.location.reload();
  }

  const onForgotPassword = () => {
    exitFromWallet();
    // setForgotPasswordFlag(true)
  }

  const forgotPasswordChange = (event: any) => {
    setForgotPassword(event.target.value);
  }

  const onForgotPasswordSubmit = () => {
    sessionStorage.setItem('hasSignedIn', 'yes');
    localStorage.setItem('setPassword', forgotPassword);
    setHasSignedIn(true);
  }

  const [connection, setConnection] = React.useState('testnet');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setConnection(event.target.value as string);
  };

  return (
    <main className="main-card">
      <div className="main-inner">
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!hasConnectWalletDone && hasScreenStatus ? (
              <div className="main-card-inner">
                {!isCreateWallet ? (
                  <Card className="rootClasses">
                    <CardContent className="first_card_content">
                      <div className="first_card_wrapper">
                        <img src="assets/img/phantom.png" alt="" className="logo__img" />
                        <h1>Phantom lite Wallet</h1>
                        <p>A crypto wallet reimagined for NFTs and Gaming</p>
                      </div>
                    </CardContent>
                    <CardActions className="first_card_action">
                      <Button variant="contained" onClick={() => onCreateWallet()}>
                        Create New Wallet
                      </Button>
                      <Button variant="contained" onClick={() => onSecretClick()} style={{ marginTop: '15px', marginLeft: '0px' }}>
                        Use Secret Recovery Phrase
                      </Button>
                    </CardActions>
                  </Card>
                ) : (
                  <Card className="rootClasses">
                    <CardContent className="second_card_content" style={{ height: "100%" }}>
                      <div className={stepperClasses.root}>
                        <div>
                          {!isStepperNextStep ? (
                            <Button onClick={handleCardBack}>
                              Back
                            </Button>
                          ) : (
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                              Back
                            </Button>
                          )}
                        </div>
                        {/* @ts-ignore */}
                        <Stepper className="stepper-heading" alternativeLabel activeStep={activeStep} >
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>

                      <div style={{ height: "100%" }}>
                        {getStepContent(activeStep)}
                      </div>
                    </CardContent>
                  </Card>
                )}
              </div>
            ) : (
              <div className="wallet-container-back">
                <div className="back-trans">
                  <div className="drop-down">
                    <img src="assets/img/favicon.png" width="60px"/>
                    <FormControl className="drop-down-btn" variant="outlined">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={connection}
                        onChange={handleChange}
                      >
                        <MenuItem value="testnet">Testnet</MenuItem>
                        <MenuItem disabled value="mainnet">Mainnet</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="wallet-container">
                    {!hasSignedIn ? (
                      <section className="login-section" style={{ height: '100%' }}>
                        <header className="login_header">
                          <p>Phantom lite Wallet</p>
                        </header>
                        <div style={{ padding: "20px", height: '100%'}}>

                          <div className="login-content">
                            <img src="assets/img/phantom.png" alt="" className="login_logo" />
                            <h1 style={{ marginTop: "20px" }}>Enter your password</h1>
                            {!isForgotPassword ? (
                              <TextField style={{ width: "100%" }} id="outlined-basic" label="Password" variant="outlined"
                                type="password"
                                name="password"
                                required={true}
                                onChange={(event) => passwordChange(event)} />
                            ) : (
                              <TextField style={{ width: "100%" }} id="outlined-basic" label="Enter new password" variant="outlined"
                                type="password"
                                name="password"
                                required={true}
                                onChange={(event) => forgotPasswordChange(event)} />
                            )}

                            <p style={{ cursor: 'pointer', width: "100%", textAlign: 'right' }} onClick={onForgotPassword}>Forgot Password</p>
                          </div>
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onUnlock} disabled={!password} variant="contained">Unlock</Button>
                          {/* {!isForgotPassword ? (
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onUnlock} disabled={!password} variant="contained">Unlock</Button>
                        ) : (
                          <Button color="primary" style={{ marginTop: "20px" }} onClick={onForgotPasswordSubmit} disabled={!forgotPassword} variant="contained">Submit</Button>
                        )} */}

                        </div>

                      </section>
                    ) : (
                      <div className="wallet-wrapper">
                        <header className="wallet-header">
                          Phantom lite Wallet &#160;
                          <Tooltip title="Copy to clipboard" arrow>
                            <span onClick={() => onCopy(address)}> ({walletCode(address)})</span>
                          </Tooltip>
                        </header>
                        <section style={{ flexGrow: "1" }}>
                          {getContent()}
                        </section>
                        <footer className="footer-tab">
                          <IconButton aria-label="Wallet Summary" onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? "active-btn" : ''} component="span">
                            <Tooltip title="Wallet Summary" arrow>
                              <MonetizationOnIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton aria-label="View NFT" onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? "active-btn" : ''} component="span">
                            <Tooltip title="View NFT" arrow>
                              <WidgetsIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton aria-label="Recent Activity" onClick={() => setSelectedTab(3)} className={selectedTab === 3 ? "active-btn" : ''} component="span">
                            <Tooltip title="Recent Activity" arrow>
                              <CompareArrowsIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton onClick={() => handleClickOpen()} aria-label="Exit from wallet" component="span">
                            <Tooltip title="Exit from wallet" arrow>
                              <LogoutIcon />
                            </Tooltip>
                          </IconButton>

                        </footer>

                        <Dialog
                          open={open}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={handleClose}
                          aria-describedby="alert-dialog-slide-description"
                          PaperProps={{
                            style: {
                              backgroundImage: "linear-gradient(to top, #1f3958, #264264, #2c4b71, #33547d, #3a5e8a)",
                              borderRadius: "20px"
                            },
                          }}
                        >
                          <DialogTitle>{"Are you sure you want to exit from wallet?"}</DialogTitle>
                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={exitFromWallet}>Yes</Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                  </div>


                  {allTransections.length > 0 ? (
                    <AllTransections transections={allTransections} />
                  ) : (
                    <img src="assets/img/favicon.png" alt="Axion" className="middle_logo" />
                  )}

                </div>
              </div>
            )}
          </>
        )}



        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
        <Footer/>
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default ConnectWallet;
