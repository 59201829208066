import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, Snackbar } from "@material-ui/core";
import './faucet.scss';
import '../../connectWallet.scss';
import Alert from "@material-ui/lab/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import { Footer } from "../../components/footer";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const Faucet = (props: any) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [contentRender, setContentRender] = useState('main');
  const [isLoading, setIsloading] = useState(false);
  const [amount, setAmount] = useState<any>('1');
  const [amountErrorMessage, setAmountErrorMessage] = useState('');
  const [recipientErrorMessage, setRecipientErrorMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [recipientAddress, setRecipientAddress] = useState('');
  const submitNAVForm = async () => {
    setIsloading(true);
    let token = await JSON.parse(localStorage.getItem('keys-from-seed') || '{}');
    let body = {to_address: recipientAddress, amount: parseFloat(amount) };
    axios.post('https://block.axionprotocol.com/airdrop-token/', body)
      .then(
        (result: any) => {
          setIsloading(false);
          if (result.data.status === 'failure') {
           
            setRecipientAddress('');
            if (result.data.info === 'Address Invalid') {
              setAmountErrorMessage('Invalid AX address!!');
              setIsSubmitDisabled(true);
            }
          } else {
            
            setRecipientAddress('');
            setAlertState({
              open: true,
              message: "Sent successfully",
              severity: "success",
            });
          }
        },
        (error) => {
          setAlertState({
            open: true,
            message: "Something went wrong!!",
            severity: "error",
          });
          setIsloading(false);
        }
      )
  }

  return (
    <main className="wallet-details-container">
      <div className="back-trans">
        <img className="wallet-logo" src="../assets/img/favicon.png" />

        <div className="trans-container">
          <div className="main-card-inner">

            <Card className="faucetCard">
              <CardContent className="first_card_content">
                <div className="first_card_wrapper">
                  <img src="../assets/img/favicon.png" alt="" className="logo__faucet" />
                  <h1>Axion Faucet</h1>
                  <p>A crypto wallet reimagined for NFTs and Gaming</p>
                </div>
                <TextField style={{ width: "100%", marginTop: 15 }} className="input-filed" id="outlined-basic" label="Enter Your Wallet Address (0x...)" variant="outlined"
                  type="text"
                  name="recipient_address"
                  value={recipientAddress}
                  required={true}
                  onChange={(event: any) => setRecipientAddress(event.target.value)
                  }
                />
              </CardContent>
              <CardActions className="faucet_card_action">
                <Button variant="contained"
                  onClick={() => submitNAVForm()}
                >
                  {
                    isLoading ? "sending" : "Send yourself AX"
                  }
                </Button>

              </CardActions>
            </Card>

          </div>

        </div>




        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
        <Footer />
      </div>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}


export default Faucet;

